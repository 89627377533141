<script setup>
	import CheckItem from "@/components/CheckItem.vue";
	// import IconCheck from "@/components/icons/IconCheck.vue";
	// import IconTransactionMinus from "@/components/icons/IconTransactionMinus.vue";
	import IconUpload from "@/components/icons/IconUpload.vue";
	import { useUserStore } from "@/store/userStore";
	import { ref } from "vue";

	const file = ref(null);
	const userStore = useUserStore();
	const userAttempts = {
		count_received_attempts: userStore.getUserReceivedAttempts || 0,
		count_spent_attempts: userStore.getUserSpentAttempts || 0,
		last_received_attempt: userStore.getUserLastReceivedAttempt || "",
		last_spent_attempt: userStore.getUserLastSpentAttempt || "",
	};
	userStore.$subscribe((mutation, state) => {
		console.log(state);
		userAttempts.count_received_attempts = state.userReceivedAttempts;
		userAttempts.count_spent_attempts = state.userSpentAttempts;
		userAttempts.last_received_attempt = state.userLastReceivedAttempt;
		userAttempts.last_spent_attempt = state.userLastSpentAttempt;
	});
	async function handleCheckSelect() {
		if (file.value.files.length) {
			const form = new FormData();
			form.append("file", file.value.files[0]);

			await userStore.sendReceipt(form);
			await userStore.updateUserReceipts();
		}
	}

	const formatedDate = (date) => {
		const d = new Date(date);
		return `${(d.getDate() > 9 ? "" : "0") + d.getDate()}.${
			(d.getMonth() + 1 > 9 ? "" : "0") + (d.getMonth() + 1)
		}.${d.getFullYear()} в ${d.getHours()}:${d.getMinutes()}`;
	};
</script>

<template>
	<div class="check">
		<div class="container">
			<h1 class="title">История</h1>
			<div class="card card--large check-attempts">
				<div class="check-attempts__card">
					<p class="check-attempts__title">
						Всего получено попыток
						<span>{{ userAttempts.count_received_attempts }}</span>
					</p>
					<p
						v-if="userAttempts.last_received_attempt !== ''"
						class="check-attempts__date"
					>
						Последняя загрузка
						{{ formatedDate(userAttempts.last_received_attempt) }}
					</p>
				</div>
				<div class="check-attempts__card">
					<p class="check-attempts__title">
						Всего потрачено попыток
						<span>{{ userAttempts.count_spent_attempts }}</span>
					</p>
					<p
						v-if="userAttempts.last_spent_attempt !== ''"
						class="check-attempts__date"
					>
						Последняя попытка
						{{ formatedDate(userAttempts.last_spent_attempt) }}
					</p>
				</div>
			</div>
			<!-- <div class="check-filters">
				<button class="card check-filters__fillter">
					<IconCheck />
					Мои чеки
					<span>14</span>
				</button>
				<button class="card check-filters__fillter">
					<IconTransactionMinus />
					Мои фото
					<span>4</span>
				</button>
			</div> -->
			<div class="check__col">
				<template v-for="(receipt, i) in userStore.getHistoryReceipts" :key="i">
					<CheckItem
						:src="receipt.file"
						:date="formatedDate(receipt.created_at)"
						:status="receipt.status"
					/>
				</template>
				<!-- <CheckItem src="https://placehold.co/400x400" status="confirmed" />
				<CheckItem src="https://placehold.co/200x400" status="confirmed" />
				<CheckItem src="https://placehold.co/100x400" status="rejected" />
				<CheckItem src="https://placehold.co/10x400" status="rejected" />
				<CheckItem src="https://placehold.co/300x200" status="confirmed" />
				<CheckItem src="https://placehold.co/600x300" status="confirmed" /> -->
			</div>
		</div>
	</div>
	<label for="" class="file check-load">
		<input
			ref="file"
			type="file"
			class="file__input"
			@input="handleCheckSelect"
		/>
		<button class="file__button">Загрузить <IconUpload /></button>
	</label>
</template>

<style scoped lang="scss">
	.check {
		padding: 12px 0px 80px;
		&__col {
			display: flex;
			flex-direction: column;
			margin-top: 24px;
			gap: 4px;
		}
	}
	.check-load {
		position: fixed;
		bottom: 90px;
		left: 50%;
		transform: translateX(-50%);
		max-width: 154px;
	}
	.check-attempts {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 24px;
		&__card {
			display: flex;
			flex-direction: column;
			gap: 8px;
		}
		&__title {
			color: var(--color-grey-100, #5f6062);
			font-size: 16px;
			font-weight: 700;
			span {
				color: var(--color-main-100, #561750);
				font-size: 14px;
				border-radius: 100px;
				background: var(--color-grey-10, #efefef);
				padding: 2px 6px;
				font-weight: 700;
			}
		}
		&__date {
			color: var(--color-grey-100, #5f6062);
			font-size: 12px;
			font-weight: 700;
			opacity: 0.5;
		}
	}
	.check-filters {
		margin-top: 16px;
		display: flex;
		align-items: center;
		gap: 4px;
		&__fillter {
			display: flex;
			align-items: center;
			gap: 8px;
			color: var(--BLACK-100, #050505);
			text-align: center;
			font-size: 14px;
			font-weight: 700;
			span {
				margin-left: -4px;
			}
			:deep(path[opacity="0.4"]) {
				opacity: 1;
				fill: var(--color-main-100, #561750);
			}
		}
	}
</style>
