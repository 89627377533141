<script setup>
	import { ref } from "vue";
	import AnimatedWool from "./icons/AnimatedWool.vue";

	defineProps({
		isLoading: {
			type: Boolean,
		},
	});
	const count = ref(1);
	const interval = setInterval(() => {
		count.value += 1;
		if (count.value > 6) {
			clearInterval(interval);
		}
	}, 1000);
</script>

<template>
	<div class="preloader" :class="{ 'preloader--active': isLoading }">
		<img
			src="../assets/img/preloader-logo.png"
			class="preloader__logo"
			alt=""
		/>
		<AnimatedWool class="preloader__wool" :style="`--count: ${count}`" />
	</div>
</template>

<style scoped lang="scss">
	.preloader {
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: #ececec;
		display: flex;
		opacity: 0;
		pointer-events: none;
		transition: 0.3s ease;
		&__logo {
			max-width: 300px;
			height: auto;
			margin: 120px auto auto;
		}
		&__wool {
			animation: bounce 0.5s ease-in alternate-reverse 6 forwards,
				move 3s linear forwards 1, rotate 3s linear 1;
			position: absolute;
			transform: translateX(-100%);
			@keyframes bounce {
				0% {
					bottom: calc(80vh / var(--count));
				}
				100% {
					bottom: 0vh;
				}
			}
		}
		&--active {
			opacity: 1;
			pointer-events: all;
		}
	}
	@keyframes move {
		0% {
			left: 0;
		}
		100% {
			left: 100%;
		}
	}
	@keyframes rotate {
		0% {
			transform: translateX(-100%) rotate(0deg);
		}
		100% {
			transform: translateX(-100%) rotate(360deg);
		}
	}
</style>
