import {
	getClientAward,
	getClientCountAttempts,
	getClientHistory,
	getLastClientAward,
	getReceiptHistory,
	postReceiptCreate,
} from "@/services/apiService";
import { defineStore } from "pinia";

export const useUserStore = defineStore("user", {
	state: () => ({
		userId: "",
		historyAwards: [],
		historyReceipts: [],
		userAttempts: 0,
		userSpentAttempts: 0,
		userReceivedAttempts: 0,
		userLastSpentAttempt: 0,
		userLastReceivedAttempt: 0,
		lastAwards: [],
		lastDataUpdate: 0,
		device: "",
	}),
	getters: {
		getUserId: (state) => state.userId,
		getHistoryAwards: (state) => state.historyAwards,
		getHistoryReceipts: (state) => state.historyReceipts,
		getUserAttempts: (state) => state.userAttempts,
		getUserSpentAttempts: (state) => state.userSpentAttempts,
		getUserReceivedAttempts: (state) => state.userReceivedAttempts,
		getUserLastSpentAttempt: (state) => state.userLastSpentAttempt,
		getUserLastReceivedAttempt: (state) => state.userLastReceivedAttempt,
		getLastAwards: (state) => state.lastAwards,
		getDeviceType: (state) => state.device,
	},
	actions: {
		setUserId(userId) {
			this.userId = userId;
		},
		setHistoryAwards(historyAwards) {
			let filteredHistory = historyAwards.awards.filter(
				(item) => item.award_id !== 0
			);
			this.historyAwards = filteredHistory;
			this.userSpentAttempts = historyAwards.total_attempts_spent;
			this.userReceivedAttempts = historyAwards.total_attempts_received;
			this.userLastSpentAttempt = historyAwards.last_attempt;
			this.userLastReceivedAttempt = historyAwards.last_download;
		},
		setLastAwards(lastAwards) {
			this.lastAwards = lastAwards;
		},
		setHistoryReceipts(historyReceipts) {
			this.historyReceipts = historyReceipts;
		},
		setUserAttempts(userAttempts) {
			this.userAttempts = userAttempts.count_attempts;
		},
		setDeviceType(device) {
			this.device = device;
		},
		async getAward() {
			return await getClientAward(this.userId).catch(() => {
				return false;
			});
		},
		async sendReceipt(formdata) {
			return await postReceiptCreate(this.userId, formdata);
		},
		async updateUserReceipts() {
			const response = await getReceiptHistory(this.userId);
			this.setHistoryReceipts(response);
		},
		async updateUserAttempts() {
			const response = await getClientCountAttempts(this.userId);
			this.setUserAttempts(response);
		},
		async updateLastAwards() {
			const response = await getLastClientAward(this.userId);
			this.setLastAwards(response);
		},
		async updateUserHistory() {
			const response = await getClientHistory(this.userId);
			this.setHistoryAwards(response);
		},
		async updateAllData() {
			console.log(
				"Попытка обновления данных:",
				`${Date.now() - this.lastDataUpdate}ms - ${this.userId}`
			);
			if (Date.now() - this.lastDataUpdate < 10000 || this.userId === "")
				return;
			await this.updateUserReceipts();
			await this.updateUserAttempts();
			await this.updateLastAwards();
			await this.updateUserHistory();
			console.groupCollapsed("Обновленные данные:");
			console.log("История наград", this.historyAwards);
			console.log("История чеков", this.historyReceipts);
			console.log("Попытки", this.userAttempts);
			console.log("Потрачено попыток", this.userSpentAttempts);
			console.log("Получено попыток", this.userReceivedAttempts);
			console.log("Последняя попытка", this.userLastSpentAttempt);
			console.log("Последняя полученная попытка", this.userLastReceivedAttempt);
			console.log("Последние 20 наград", this.lastAwards);
			console.log("Тип устройства", this.device);
			console.log("Пользователь", this.userId);
			console.log(
				"Последнее обновление",
				`${Date.now() - this.lastDataUpdate}ms прошло`
			);
			console.groupEnd();
			this.lastDataUpdate = Date.now();
		},
	},
});
